import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAuthOptionMutationVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
}>;


export type GetAuthOptionMutation = { __typename?: 'Mutation', getAuthOption: { __typename?: 'AuthOption', applicationType?: string | null, loginMethod?: string | null, smsError?: string | null } };


export const GetAuthOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetAuthOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAuthOption"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicationType"}},{"kind":"Field","name":{"kind":"Name","value":"loginMethod"}},{"kind":"Field","name":{"kind":"Name","value":"smsError"}}]}}]}}]} as unknown as DocumentNode;
export type GetAuthOptionMutationFn = Apollo.MutationFunction<GetAuthOptionMutation, GetAuthOptionMutationVariables>;

/**
 * __useGetAuthOptionMutation__
 *
 * To run a mutation, you first call `useGetAuthOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAuthOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAuthOptionMutation, { data, loading, error }] = useGetAuthOptionMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useGetAuthOptionMutation(baseOptions?: Apollo.MutationHookOptions<GetAuthOptionMutation, GetAuthOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAuthOptionMutation, GetAuthOptionMutationVariables>(GetAuthOptionDocument, options);
      }
export type GetAuthOptionMutationHookResult = ReturnType<typeof useGetAuthOptionMutation>;
export type GetAuthOptionMutationResult = Apollo.MutationResult<GetAuthOptionMutation>;
export type GetAuthOptionMutationOptions = Apollo.BaseMutationOptions<GetAuthOptionMutation, GetAuthOptionMutationVariables>;