import { ColumnDef, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import { useMemo, useState } from 'react';
import { LeadsFragment } from 'pages/leads/api/fragmetns/generated/Leads.fragment';
import { RU_DATE } from 'utils/helpers/constVariables';
import { userFullName } from 'utils/helpers/userFullName';
import FacilityCell from 'pages/leads/components/facilityCell';
import { leadStatus } from 'pages/leads/utils/hooks/leadStatus';
import CheckCircleFill12 from 'assets/icons/CheckCircleFill12';
import { isStockCompleteFn } from 'pages/leads/utils/hooks/isStockCompleteFn';
import { isStatusAcceptedFn } from 'pages/leads/utils/hooks/isStatusAcceptedFn';
import Edit from 'assets/icons/edit';
import NewSchedule from 'assets/icons/newSchedule';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { successToast } from 'utils/helpers/notify';
import CopyIcon from 'assets/icons/CopyIcon';
import Tooltip from 'antd/es/tooltip';
import LeadPayCheckbox from 'pages/leads/components/LeadPayCheckbox';

const columnHelper = createColumnHelper<LeadsFragment>();

export const useLeadsTable = (data: LeadsFragment[]) => {
  const columns: ColumnDef<LeadsFragment>[] = useMemo(() => {
    return [
      columnHelper.group({
        id: 'referrers',
        header: 'Рефереры',
        columns: [
          {
            id: 'createdAt',
            header: 'Дата',
            size: 105,
            minSize: 105,
            cell: ({ row: { original } }) => dayjs(original?.createdAt).format(RU_DATE),
          },
          {
            id: 'date',
            header: 'ФИО',
            size: 175,
            cell: ({ row: { original } }) => (
              <Link to={`/users/${original?.user?.referrer?.id}`} className="link">
                {userFullName(original?.user?.referrer).trim() || 'Без имени'}
              </Link>
            ),
          },
          {
            id: 'leadPhone',
            header: 'Телефон',
            size: 190,
            cell: ({ row: { original } }) => (
              <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
                <span>{convertPhoneNumberToNormal(original?.user?.referrer?.phone)}</span>
                <button
                  className={'group-hover/number:block hidden'}
                  onClick={() => {
                    if (!original?.user?.referrer?.phone) return;
                    navigator.clipboard.writeText(original.user.referrer.phone).then(() => {
                      successToast('Номер скопирован', 1000);
                    });
                  }}
                >
                  <CopyIcon />
                </button>
              </div>
            ),
          },
          {
            id: 'facility',
            header: 'Объект',
            size: 175,
            cell: ({ row: { original } }) => <FacilityCell user={original?.user?.referrer} />,
          },
          {
            id: 'checkbox',
            header: 'Отправлено в оплату',
            size: 175,
            cell: ({ row: { original } }) => <LeadPayCheckbox original={original} />,
          },
          {
            id: 'referralPaymentDatetime',
            header: 'Дата оплаты',
            size: 175,
            cell: ({ row: { original } }) => (
              <div>
                {original.referrerPaymentDatetime ? dayjs(original.referrerPaymentDatetime).format(RU_DATE) : null}
              </div>
            ),
          },
        ],
      }),

      columnHelper.group({
        id: 'referrals',
        header: 'Рефералы',
        columns: [
          {
            id: 'lead',
            header: 'ФИО',
            size: 145,
            cell: ({ row: { original } }) => (
              <Link to={`/users/${original?.user?.id}`} className="link">
                {userFullName(original?.user).trim() || 'Без имени'}
              </Link>
            ),
          },
          {
            id: 'referPhone',
            header: 'Телефон',
            size: 180,
            cell: ({ row: { original } }) => (
              <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
                <span>{convertPhoneNumberToNormal(original.user?.phone)}</span>
                <button
                  className={'group-hover/number:block hidden'}
                  onClick={() => {
                    if (!original.user?.phone) return;
                    navigator.clipboard.writeText(original.user?.phone).then(() => {
                      successToast('Номер скопирован', 1000);
                    });
                  }}
                >
                  <CopyIcon />
                </button>
              </div>
            ),
          },
          {
            id: 'called',
            header: 'Обработан',
            size: 120,
            cell: ({ row: { original } }) =>
              original?.isProcessed ? (
                <Check className="text-smena_green-dark" />
              ) : (
                <Across className="text-smena_red" />
              ),
          },
          {
            id: 'facilityReferrals',
            header: 'Объект',
            size: 175,
            cell: ({ row: { original } }) => <FacilityCell user={original?.user} />,
          },
          {
            id: 'status',
            header: 'Статус',
            size: 130,
            cell: ({ row: { original } }) => leadStatus(original?.status),
          },
          {
            id: 'shifts',
            header: 'Смены',
            size: 110,
            cell: ({ row: { original } }) => {
              const isStatusAccepted = isStatusAcceptedFn(original);
              const isStockComplete = isStockCompleteFn(original);
              return isStatusAccepted ? (
                <div>
                  <div className={clsx(isStockComplete ? 'text-smena_green-dark' : '', 'Table-small flex')}>
                    <span className={clsx('text-smena_green-dark')}>
                      {isStockComplete ? original.countShifts : original?.countShiftsGood}
                    </span>
                    <span>/{original.countShifts}</span>
                    {isStockComplete && (
                      <CheckCircleFill12 className="inline-flex self-center ml-1 text-smena_green-dark" />
                    )}
                  </div>
                  <span className="Table-small text-smena_red underline">+ {original?.countShiftsBad} плохих</span>
                </div>
              ) : null;
            },
          },
          {
            id: 'inviteFriendDate',
            header: 'Дата достижения',
            size: 160,
            cell: ({ row: { original } }) =>
              original?.inviteFriendDate ? dayjs(original?.inviteFriendDate).format(RU_DATE) : null,
          },
          {
            id: 'actions',
            header: 'Действия',
            size: 115,
            cell: ({ row: { original } }) => {
              const isStatusAccepted = isStatusAcceptedFn(original);
              return (
                <div className="flex items-center">
                  <Tooltip title={'Редактировать информацию о лиде'}>
                    <Link
                      to={`/offers/leads/${original?.id}`}
                      className="text-smena_text mr-4 transform hover:scale-110"
                    >
                      <Edit />
                    </Link>
                  </Tooltip>
                  {isStatusAccepted ? (
                    <Tooltip title={'Перейти в график'}>
                      <Link to={`/users/${original?.user?.id}/schedule`}>
                        <NewSchedule />
                      </Link>
                    </Tooltip>
                  ) : null}
                </div>
              );
            },
          },
        ],
      }),
    ];
  }, []);

  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      createdAt: true,
      inviteFriendDate: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
