import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import Garbage from 'assets/icons/GarbageArrow';
import { useMemo, useState } from 'react';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { useCities } from 'utils/api/useCities';
import { useChangeVacancyStatusMutation } from 'pages/vacancies/api/mutations/generated/ChangeVacancyStatus';
import { VacancyFragment } from 'pages/vacancy/api/fragments/generated/Vacancy.fragment';
import { getCity } from 'utils/helpers/get/getCity';
import { getDayNightRu } from 'utils/helpers/get/getDayNightRu';
import { getScheduleTypeName } from 'utils/api/useGetScheduleTypes/getScheduleTypeName';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import { allUnits } from 'utils/helpers/lists/units';
import Tooltip from 'antd/es/tooltip';

export const useFacilityGroupVacanciesTable = (data: VacancyFragment[]) => {
  const { scheduleTypes } = useGetScheduleTypes();
  const [changeStatus, { loading }] = useChangeVacancyStatusMutation();
  const vacancyTime = (time: string) => {
    return time.split(', ').length > 1 ? time.split(', ').map(el => <div key={el}>{el}</div>) : time;
  };
  const { cities } = useCities();
  const columns: ColumnDef<VacancyFragment>[] = useMemo(() => {
    return [
      {
        id: 'position',
        header: 'Профессия',
        size: 200,
        minSize: 200,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/vacancies/${original.id}`} className="link">
              {original.position?.name}
            </Link>
          );
        },
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 200,
        minSize: 200,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/facilities/${original.facilityId}`} className="link">
              {original.facility?.name}
            </Link>
          );
        },
      },
      {
        id: 'city',
        header: 'Город',
        size: 200,
        minSize: 200,
        cell: ({ row: { original } }) => getCity({ cityId: original.facility.cityId, cities })?.name,
      },
      {
        id: 'timeStart',
        header: 'Начало смены',
        size: 100,
        minSize: 100,
        cell: ({ row: { original } }) => vacancyTime(original.timeStart),
      },
      {
        id: 'timeEnd',
        header: 'Конец смены',
        size: 100,
        minSize: 100,
        cell: ({ row: { original } }) => vacancyTime(original.timeEnd),
      },
      {
        id: 'day/night',
        header: 'День/Ночь',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => getDayNightRu(original.period),
      },
      {
        id: 'schedule',
        header: 'График',
        size: 120,
        cell: ({ row: { original } }) => {
          original.scheduleTypesIds?.map(el => getScheduleTypeName(scheduleTypes, el)).join(', ');
        },
      },
      {
        id: 'section',
        header: 'Отдел',
        size: 180,
        cell: ({ row: { original } }) => original.section,
      },
      {
        id: 'payout',
        header: 'Тип оплаты',
        size: 130,
        minSize: 130,
        cell: ({ row: { original } }) => payoutTypes.find(payoutType => payoutType.id === original.payout)?.name,
      },
      {
        id: 'unit',
        header: 'Ед. измерения',
        size: 130,
        minSize: 130,
        cell: ({ row: { original } }) => allUnits.find(unit => original.unit === unit.id)?.name,
      },
      {
        id: 'rate',
        header: 'ставка, ₽',
        minSize: 115,
        cell: ({ row: { original } }) => `${original.hourRate} ₽`,
      },
      {
        id: 'shiftCost',
        header: 'Стоимость смены, ₽',
        size: 130,
        cell: ({ row: { original } }) =>
          original.isPublishCostOfShift
            ? original.isRangeCost
              ? `${original.shiftCostFrom} - ${original.shiftCostUpTo}`
              : original.averageCost
            : '',
      },
      {
        id: 'status',
        header: 'Статус',
        size: 87,
        cell: ({ row: { original } }) =>
          original.is_active ? <Check className="text-smena_green-dark" /> : <Across className="text-smena_red" />,
      },
      {
        id: 'erpSourceId',
        header: 'ERP ID Источника',
        size: 130,
        cell: ({ row: { original } }) => original.erpSourceId,
      },
      {
        id: 'erpGroupId',
        header: 'ERP ID Группы',
        size: 130,
        cell: ({ row: { original } }) => original.erpGroupId,
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 115,
        minSize: 115,
        cell: ({ row: { original } }) => {
          return (
            <Tooltip title={'Изменить статус активности'}>
              <button
                disabled={loading}
                onClick={() =>
                  changeStatus({
                    variables: { id: original.id },
                    update: cache => {
                      cache.modify({
                        id: `Vacancy:${original.id}`,
                        fields: {
                          is_active(prev: boolean) {
                            return !prev;
                          },
                        },
                      });
                    },
                  })
                }
              >
                {original.is_active ? <GarbageArrowLess /> : <Garbage />}
              </button>
            </Tooltip>
          );
        },
      },
    ];
  }, [changeStatus, cities, loading, scheduleTypes]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      position: true,
      facility: true,
      city: true,
      timeStart: true,
      schedule: true,
    },
  });
};
