import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import React, { useMemo, useState } from 'react';
import { successToast } from 'utils/helpers/notify';
import FacilityCell from 'pages/users/components/ourUsers/facilityCell';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import CopyIcon from 'assets/icons/CopyIcon';
import Actions from './users/actions';
import { getPersonalInfoIcon } from 'pages/users/lib/getPersonalInfoIcon';
import { showContracts } from './users/contracts';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import Tooltip from 'antd/es/tooltip';

export const useUsersTable = (data: UsersPaginatedFragment[]) => {
  const columns: ColumnDef<UsersPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'lastname',
        header: 'Фамилия',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.id}`} className="link">
              {original.lastname ? original.lastname : 'Нет информации'}
            </Link>
          );
        },
      },
      {
        id: 'firstname',
        header: 'Имя',
        size: 150,
        minSize: 150,
        accessorKey: 'firstname',
      },
      {
        id: 'patronymic',
        header: 'Отчество',
        size: 150,
        minSize: 150,
        accessorKey: 'patronymic',
      },
      {
        id: 'role',
        header: 'Роль',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => getRoleRu(original.role),
      },
      {
        id: 'phone',
        header: 'Телефон',
        minSize: 185,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(original.phone)}</span>
              <Tooltip title={'Скопировать'}>
                <button
                  className={'group-hover/number:block hidden'}
                  onClick={() => {
                    navigator.clipboard.writeText(original.phone).then(() => {
                      successToast('Номер скопирован', 1000);
                    });
                  }}
                >
                  <CopyIcon />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: 'personalInfo',
        header: 'Перс. данные',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>{getPersonalInfoIcon(original.registrationState?.personalInfo)}</div>
          );
        },
      },
      {
        id: 'taxPartnerInfo',
        header: 'Мой налог',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>
              {original.registrationState?.taxPartnerInfo ? (
                <Check className={'text-smena_green'} />
              ) : (
                <Across className={'text-smena_red'} />
              )}
            </div>
          );
        },
      },
      {
        id: 'paymentInfo',
        header: 'Платеж. Данные',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>
              {original.registrationState?.paymentInfo ? (
                <Check className={'text-smena_green'} />
              ) : (
                <Across className={'text-smena_red'} />
              )}
            </div>
          );
        },
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return <FacilityCell user={original} />;
        },
      },
      {
        id: 'contracts',
        header: 'Документ',
        cell: ({ row: { original } }) => {
          return showContracts(original.contracts);
        },
      },
      {
        id: 'currentVersion',
        header: 'Версия',
        cell: ({ row: { original } }) => original.currentVersion,
      },
      {
        id: 'isActive',
        header: 'Статус',
        size: 96,
        minSize: 96,
        cell: ({ row: { original } }) => {
          return original.isActive ? <Check className={'text-smena_green'} /> : <Across className="text-smena_red" />;
        },
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => <Actions original={original} />,
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      lastname: true,
      requisiteUpdatedAt: true,
      personalInfo: true,
      taxPartnerInfo: true,
      paymentInfo: true,
      version: true,
      currentVersion: true,
    },
  });
};
