import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getReferralProgramStatus } from '../utils/getReferralProgramStatus';
import { getReferralProgramStatusColor } from '../utils/getReferralProgramStatusColor';

interface Props {
  program: any;
  prevProgram: any;
  isLastItem: boolean;
}

const ReferralProgram = ({ program, prevProgram, isLastItem }: Props) => {
  const { settingId } = useParams();

  const isDraft = program.status === 'DRAFT';

  const getPrevValue = (key: string) => prevProgram?.[key] || (!isLastItem && 'Без названия');

  const renderArrow = () => (!isLastItem ? <span className="text-blue-500"> {' --> '}</span> : null);

  const getName = () => {
    const name = program.name || 'Без названия';
    return (
      <>
        {isDraft ? 'Без названия' : getPrevValue('name')}
        {renderArrow()}
        {name}
      </>
    );
  };

  const getDescription = () => {
    const description = program.description || 'Без описания';
    return (
      <>
        {isDraft ? 'Без описания' : getPrevValue('description')}
        {renderArrow()}
        {description}
      </>
    );
  };

  const getNumberOfReferrals = () => {
    const numberOfReferrals = String(program.numberOfReferrals || '');
    return (
      <>
        {!isDraft && getPrevValue('numberOfReferrals')}
        {!isDraft && renderArrow()}
        {numberOfReferrals}
      </>
    );
  };

  const getNumberOfGoodShifts = () => {
    const numberOfGoodShifts = String(program.numberOfGoodShifts || '');
    return (
      <>
        {!isDraft && getPrevValue('numberOfGoodShifts')}
        {!isDraft && renderArrow()}
        {numberOfGoodShifts}
      </>
    );
  };

  const getRewardForTheReferer = () => {
    const rewardForTheReferer = String(program.rewardForTheReferer || '');
    return (
      <>
        {!isDraft && getPrevValue('rewardForTheReferer')}
        {!isDraft && renderArrow()}
        {rewardForTheReferer}
      </>
    );
  };

  const getRewardForTheReferral = () => {
    const rewardForTheReferral = String(program.rewardForTheReferral || '');
    return (
      <>
        {!isDraft && getPrevValue('rewardForTheReferral')}
        {!isDraft && renderArrow()}
        {rewardForTheReferral}
      </>
    );
  };

  const getHistory = () => {
    const stories = program.stories || [];
    const storiesCount = stories.length;
    return storiesCount > 0 ? <>Историй добавлено: {storiesCount}</> : null;
  };

  const getNotifyLetters = () => {
    const notifyLetters = program.notifyLetters || [];
    const notifyLettersCount = notifyLetters.length;
    return notifyLettersCount > 0 ? <>Пуш-уведомлений добавлено: {notifyLettersCount}</> : null;
  };

  const formatDate = (isoString: string) => {
    return dayjs(isoString).format('D MMMM YYYY, HH:mm');
  };

  return (
    <Link
      className={`${settingId === program.id && 'bg-smena_gray-5'} p-5 rounded-lg font-inter break-words`}
      to={`/offers/leads/setting/${program?.id}`}
    >
      <div className="flex items-center justify-between">
        <div className="H4">
          {program.startDatetime ? `C ${formatDate(program.startDatetime)}` : 'Дата и время не указаны'}
        </div>
        <div className={`${getReferralProgramStatusColor(program.status)} rounded-2xl pr-3 pl-3 pt-0.5 pb-0.5`}>
          {getReferralProgramStatus(program.status)}
        </div>
      </div>

      {/* {program.status === 'DRAFT' && (
        <div className="flex items-center justify-center text-smena_text-secondary h-[70px]">Никаких изменений</div>
      )} */}

      {program.name && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Название</div>
          <div>{getName()}</div>
        </div>
      )}

      {program.description && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Описание</div>
          <div>{getDescription()}</div>
        </div>
      )}

      {program.numberOfReferrals > 0 && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Кол-во рефералов</div>
          <div>{getNumberOfReferrals()}</div>
        </div>
      )}

      {program.numberOfGoodShifts && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Кол-во хорошо отработанных смен</div>
          <div>{getNumberOfGoodShifts()}</div>
        </div>
      )}

      {program.rewardForTheReferer > 0 && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Вознаграждение реферера</div>
          <div>{getRewardForTheReferer()}</div>
        </div>
      )}

      {program.rewardForTheReferral > 0 && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Вознаграждение реферала</div>
          <div>{getRewardForTheReferral()}</div>
        </div>
      )}

      {program.stories.length > 0 && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">История</div>
          <div>{getHistory()}</div>
        </div>
      )}

      {program.notifyLetters.length > 0 && (
        <div className="mt-1">
          <div className="Body1 text-smena_text-secondary">Пуш-уведомление</div>
          <div>{getNotifyLetters()}</div>
        </div>
      )}
    </Link>
  );
};

export default ReferralProgram;
