import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { regTimeAtVacancyPage } from 'utils/helpers/constVariables';
import { isInt } from 'utils/helpers/isInt';
import { useCreateVacancyMutation } from '../../api/mutations/generated/CreateVacancy';
import { useUpdateVacancyMutation } from '../../api/mutations/generated/UpdateVacancy';
import { useVacancy } from 'utils/api/useVacancy';
import { PayoutFrequency, PayoutType, ShiftTime, UnitType } from '../../../../generated/graphql';
import { createVacancyCache } from '../../../vacancies/api/cache/createVacancyCache';
import { useCreateVacancyInBatchMutation } from '../../api/mutations/generated/CreateVacancyInBatch';
import { useFacilityById } from '../../../../utils/api/useFacility';

const useVacancyPage = () => {
  const navigate = useNavigate();
  const param = useParams();

  const facilityGroupId = param.id;
  const facilityId = param.facilityId;

  const { vacancy, vacancyId, error } = useVacancy();
  const { facility } = useFacilityById(facilityId);
  const facGroupId = facilityGroupId || facility?.facilityGroupId || vacancy?.facility?.facilityGroupId;

  const [createVacancy, { loading: createVacancyLoading }] = useCreateVacancyMutation({
    update: (cache, { data }) => createVacancyCache(cache, { data, facilityId: facilityId }),
  });
  const [updateVacancy, { loading: updateVacancyLoading }] = useUpdateVacancyMutation();
  const [createVacancyInBatch] = useCreateVacancyInBatchMutation();

  const loading = useMemo(
    () => createVacancyLoading || updateVacancyLoading,
    [createVacancyLoading, updateVacancyLoading],
  );

  const initialValues = {
    facilityId: facilityId || vacancy?.facilityId,
    facilityGroupId: facGroupId,
    scheduleTypesIds: vacancy?.scheduleTypesIds || [],
    positionId: vacancy?.positionId,
    timeStart: vacancy?.timeStart || '',
    timeEnd: vacancy?.timeEnd || '',
    period: vacancy?.period,
    section: vacancy?.section || '',
    payout: vacancy?.payout || 'HOURLY',
    unit: vacancy?.unit || 'HOUR',
    hourRate: String(isInt(vacancy?.hourRate)) || '0',
    lunchDuration: vacancy?.lunchDuration || 0,
    probationPeriod: vacancy?.probationPeriod || 0,
    hasEducation: Boolean(vacancy?.hasEducation),
    isActive: vacancy?.is_active || false,
    isPublishCostOfShift: vacancy?.isPublishCostOfShift || false,
    isRangeCost: vacancy?.isRangeCost || false,
    averageCost: vacancy?.averageCost || 0,
    shiftCostFrom: vacancy?.shiftCostFrom || '0',
    shiftCostUpTo: vacancy?.shiftCostUpTo || '0',
    erpSourceId: vacancy?.erpSourceId || '',
    erpGroupId: vacancy?.erpGroupId || '',
    cityAndFacility: [],
    description: vacancy?.description ?? '',
    payoutFrequency: vacancy?.payoutFrequency || PayoutFrequency.Monthly,
    responsibilities: vacancy?.responsibilities ?? '',
    requirements: vacancy?.requirements ?? '',
    workwear: !!vacancy?.workwear,
    minAge: vacancy?.minAge,
    maxAge: vacancy?.maxAge,
    sex: vacancy?.sex,
    citizenshipsIds: vacancy?.citizenshipsIds || [],
    transport: vacancy?.transport ?? '',
    notes: vacancy?.notes ?? '',
    documentsNotes: vacancy?.documentsNotes ?? '',
    requiredDocuments: vacancy?.requiredDocuments || [],
  };
  const validate = (values: typeof initialValues) => {
    const errors: any = {};
    if (vacancyId && !facilityId) {
      if (!values.facilityId) {
        errors.facilityId = 'Вы не указали объект';
      }
    }
    if (!values.positionId) {
      errors.positionId = 'Вы не указали должность';
    }
    if (!values.timeStart) {
      errors.timeStart = 'Вы не указали время начала';
    } else {
      if (values.timeStart.split(', ').filter(el => !regTimeAtVacancyPage.test(el)).length > 0) {
        errors.timeStart = 'Укажите время формате 00:00';
      }
    }
    if (!values.timeEnd) {
      errors.timeEnd = 'Вы не указали время окончания';
    } else if (values.timeEnd.split(', ').filter(el => !regTimeAtVacancyPage.test(el)).length > 0) {
      errors.timeEnd = 'Укажите время формате 00:00';
    }
    if (!values.period) {
      errors.period = 'Вы не указали период';
    }
    if (!values.scheduleTypesIds) {
      errors.schedule = 'Выберите значение графика';
    }
    if (!values.hourRate) {
      errors.hourRate = 'Вы не указали ставку';
    }
    if (Number(values.hourRate) === 0) {
      errors.hourRate = 'Ставка не может быть равна 0';
    }
    if (Number(values.hourRate) > 1000) {
      errors.hourRate = 'Ставка не может быть больше 1000';
    }
    if (values.minAge && values.maxAge && values.minAge > values.maxAge) {
      errors.minAge = 'Минимальный возраст не может быть больше максимального';
    }
    if (values.minAge && values.minAge < 16) {
      errors.minAge = 'Минимальный возраст не может быть меньше 16 лет';
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const {
      facilityId,
      positionId,
      timeStart,
      timeEnd,
      period,
      section,
      hourRate,
      scheduleTypesIds,
      isActive,
      payout,
      unit,
      isPublishCostOfShift,
      isRangeCost,
      averageCost,
      shiftCostFrom,
      shiftCostUpTo,
      erpSourceId,
      erpGroupId,
      facilityGroupId,
      sex,
      citizenshipsIds,
      transport,
      description,
      responsibilities,
      requirements,
      workwear,
      minAge,
      maxAge,
      payoutFrequency,
      notes,
      documentsNotes,
      requiredDocuments,
      lunchDuration,
      probationPeriod,
      hasEducation,
    } = values;

    const vacancyVariables = {
      positionId,
      timeStart: timeStart.trim(),
      timeEnd: timeEnd.trim(),
      period: period?.toUpperCase() as ShiftTime,
      scheduleTypesIds,
      section: section.trim(),
      payout: payout as PayoutType,
      unit: unit as UnitType,
      hourRate: parseFloat(hourRate),
      isActive,
      isPublishCostOfShift,
      isRangeCost,
      averageCost: Number(averageCost),
      shiftCostFrom: Number(shiftCostFrom),
      shiftCostUpTo: Number(shiftCostUpTo),
      erpSourceId,
      erpGroupId,
      sex,
      citizenshipsIds,
      transport,
      description,
      responsibilities,
      requirements,
      workwear,
      minAge: Number(minAge),
      maxAge: Number(maxAge),
      payoutFrequency,
      notes,
      documentsNotes,
      requiredDocuments,
      lunchDuration,
      probationPeriod,
      hasEducation,
      schedule: '',
    };
    if (!vacancyId && !facilityId && facilityGroupId) {
      createVacancyInBatch({
        variables: {
          input: {
            citiesAndFacilitiesIds: values.cityAndFacility,
            facilityGroupId,
            ...vacancyVariables,
          },
        },
      })
        .then(() => {
          successToast('Вакансии созданы');
          navigate(-1);
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    } else if (vacancy?.id) {
      updateVacancy({
        variables: {
          id: vacancyId,
          input: { facilityId, ...vacancyVariables },
        },
      })
        .then(e => {
          if (e.data?.updateVacancy) {
            successToast('Вакансия изменена');
            navigate(-1);
          }
        })
        .catch(e => {
          if (JSON.stringify(e).includes('Int cannot represent non 32-bit signed integer value')) {
            errorToast('Вы ввели слишком большое число в поле Ставка');
          } else errorToast(errorHandler(e));
        });
    } else {
      createVacancy({
        variables: {
          input: { facilityId, ...vacancyVariables },
        },
      })
        .then(e => {
          if (e.data?.createVacancy) {
            successToast('Вакансия создана');
            navigate(-1);
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    }
  };
  return { initialValues, validate, onSubmit, loading, facGroupId, error };
};

export default useVacancyPage;
