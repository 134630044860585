import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';
import { RequestProgressFragment } from 'pages/facilityUpdateRequest/api/fragmetns/generated/RequestProgress.fragment';

export const progressPercent = (total: number, current: number) => (current / total) * 100;
export const progressPercentObj = (request?: RequestProgressFragment | PaginatedRequestFragment) => {
  if (request) {
    const { shiftsScheduleCount, shiftsPlanCount, hoursPlanCount, hoursScheduleCount } = request;
    return {
      shiftsProgress: progressPercent(shiftsPlanCount, shiftsScheduleCount),
      hoursProgress: progressPercent(hoursPlanCount, hoursScheduleCount),
    };
  }
  return {
    shiftsProgress: 0,
    hoursProgress: 0,
  };
};

export const percentColor = (percent: number) => (percent > 100 ? '#FF555F' : percent === 100 ? '#3DDD7D' : '#397DFF');
