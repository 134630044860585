import * as Types from '../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReferralProgramsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReferralProgramsQuery = { __typename?: 'Query', getReferralPrograms: Array<{ __typename?: 'ReferralProgramEntity', id: string, name?: string | null, description?: string | null, numberOfReferrals?: number | null, numberOfGoodShifts?: number | null, rewardForTheReferer?: number | null, rewardForTheReferral?: number | null, startDatetime?: any | null, endDatetime?: any | null, status: Types.ReferralProgramStatusEnum, stories: Array<{ __typename?: 'Story', id: string }>, notifyLetters: Array<{ __typename?: 'NotifyLetter', id: string }> }> };


export const GetReferralProgramsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetReferralPrograms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getReferralPrograms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"numberOfReferrals"}},{"kind":"Field","name":{"kind":"Name","value":"numberOfGoodShifts"}},{"kind":"Field","name":{"kind":"Name","value":"rewardForTheReferer"}},{"kind":"Field","name":{"kind":"Name","value":"rewardForTheReferral"}},{"kind":"Field","name":{"kind":"Name","value":"startDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"endDatetime"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"stories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"notifyLetters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetReferralProgramsQuery__
 *
 * To run a query within a React component, call `useGetReferralProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>(GetReferralProgramsDocument, options);
      }
export function useGetReferralProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>(GetReferralProgramsDocument, options);
        }
export function useGetReferralProgramsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>(GetReferralProgramsDocument, options);
        }
export type GetReferralProgramsQueryHookResult = ReturnType<typeof useGetReferralProgramsQuery>;
export type GetReferralProgramsLazyQueryHookResult = ReturnType<typeof useGetReferralProgramsLazyQuery>;
export type GetReferralProgramsSuspenseQueryHookResult = ReturnType<typeof useGetReferralProgramsSuspenseQuery>;
export type GetReferralProgramsQueryResult = Apollo.QueryResult<GetReferralProgramsQuery, GetReferralProgramsQueryVariables>;