import React, { useState } from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import { Spoiler } from 'ui/spoiler';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import dayjs from 'dayjs';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SimpleCheckbox from 'ui/checkbox';
import Status from './status';
import EditBonusProgramPopup from './editPopup';
import RemoveBonusProgramPopup from './removeBonusPopup';
import { Form, Formik } from 'formik';
import usePromotionForm, { Bonus } from './form';
import { repeatConditionOptions, rewardTypeOptions } from '../../helpers/const';
import { WarningExclamationSmall } from 'assets/icons/WarningExclamationSmall';
import { morph } from 'utils/helpers/morph';
import FacilitiesPopup from './facilitiesPopup';
import { PromotionStatusEnum } from 'generated/graphql';
import { clsx } from 'clsx';

type Props = { el: Bonus };

const Promotion = ({ el }: Props) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showFacilitiesPopup, setShowFacilitiesPopup] = useState(false);
  const { initialValues, validate, onSubmit } = usePromotionForm({ el, setShow: setShowEditPopup });
  const onRemovePromotion = () => {
    setShowRemovePopup(true);
  };
  const isExist = !!el['__typename'];
  return (
    <Spoiler
      header={
        <div className={'flex items-center gap-x-2 bg-smena_gray-2 px-6 py-3 rounded-lg'}>
          <span className={'Subtitle2'}>{el.name || 'Без названия'}</span>
          <Status status={el.status} />
        </div>
      }
      className={'col-span-full'}
      arrowColor={'text-primary'}
      arrowButtonCss={'px-4'}
      defaultShow={el.status === 'Blank'}
    >
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, setFieldValue, errors, handleChange, validateForm, touched, handleBlur, setFieldTouched }) => {
          const isDeleted = values.status === PromotionStatusEnum.Deleted;
          const onPublishBonus = () => {
            validateForm(values).then(res => {
              if (Object.entries(res).length) {
                for (const key in res) {
                  setFieldTouched(key);
                }
                return;
              }
              setShowEditPopup(true);
            });
          };

          return (
            <Form id="editBonusProgramForm" className={'flex flex-col gap-y-6'}>
              <span className={'Subtitle2 text-smena_text-secondary'}>Общая информация</span>
              <SimpleInput
                label={'Название'}
                placeholder={'Введите название'}
                name={'name'}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name ? errors.name : undefined}
              />
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Требования</span>
              <div className={'grid grid-cols-1 gap-x-6'}>
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Кол-во смен'}
                  placeholder={'Введите кол-во смен'}
                  name={'requirementShiftCount'}
                  type={'number'}
                  value={values.requirementShiftCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.requirementShiftCount && errors.requirementShiftCount
                      ? errors.requirementShiftCount
                      : undefined
                  }
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Вознаграждение</span>
              <div className={'grid grid-cols-2 gap-x-6'}>
                <SimpleSelect
                  divClassName={'col-span-1'}
                  label={'Тип вознаграждения'}
                  options={rewardTypeOptions}
                  value={values.rewardType}
                  placeholder={'Выберите тип награждения'}
                  onBlur={() => {
                    setFieldTouched('rewardType');
                  }}
                  onChange={value => {
                    setFieldValue('rewardType', value);
                  }}
                  error={touched.rewardType && errors.rewardType ? errors.rewardType : undefined}
                />
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Вознаграждение'}
                  placeholder={'1000'}
                  type={'number'}
                  name={'rewardSum'}
                  value={values.rewardSum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.rewardSum && errors.rewardSum ? errors.rewardSum : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Условия аннулирования</span>
              <p className={'Body1 text-smena_text-secondary col-span-full w-8/12'}>
                Вознаграждения, указанные в данной программе, будут аннулированы при превышении допустимого количества
                плохих смен.
              </p>
              <div className={'grid grid-cols-2'}>
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Кол-во плохих смен'}
                  placeholder={'10'}
                  type={'number'}
                  name={'badShiftCount'}
                  value={values.badShiftCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.badShiftCount && errors.badShiftCount ? errors.badShiftCount : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Дополнительно</span>
              <div className={'grid grid-cols-2'}>
                <SimpleSelect
                  divClassName={'col-span-1'}
                  label={'Сброс накопленного бонуса'}
                  options={repeatConditionOptions}
                  placeholder={'Выберите повтор'}
                  onChange={value => {
                    setFieldValue('repeatCondition', value);
                  }}
                  onBlur={() => {
                    setFieldTouched('repeatCondition');
                  }}
                  value={values.repeatCondition}
                  error={touched.repeatCondition && errors.repeatCondition ? errors.repeatCondition : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Сроки проведения</span>
              <div className={'grid grid-cols-3 gap-6'}>
                <DatePickerInput
                  divClassName={'col-span-1'}
                  label={'Дата начала'}
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.dateFrom}
                  onChange={value => {
                    setFieldValue('dateFrom', value);
                  }}
                  onBlur={() => {
                    setFieldTouched('dateFrom');
                  }}
                  minDate={dayjs()}
                  error={touched.dateFrom && errors.dateFrom ? errors.dateFrom : undefined}
                />
                <TimePickerInput
                  className={'col-span-1'}
                  label={'Время начала'}
                  placeholder={dayjs().format(TIME_DATE)}
                  value={values.timeFrom}
                  onChange={value => {
                    setFieldValue('timeFrom', value).then(() => setFieldTouched('timeFrom'));
                  }}
                  onBlur={() => {
                    setFieldTouched('timeFrom');
                  }}
                  error={touched.timeFrom && errors.timeFrom ? errors.timeFrom : undefined}
                />
                <div className={'col-span-1'}></div>
                <DatePickerInput
                  divClassName={'col-span-1'}
                  label={'Дата завершения'}
                  disabled={values.endless}
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.dateTo === null || values.dateTo.isValid() ? values.dateTo : null}
                  onChange={value => {
                    setFieldValue('dateTo', value);
                  }}
                />
                <TimePickerInput
                  className={'col-span-1'}
                  label={'Время завершения'}
                  disabled={values.endless}
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.timeTo === null || values.timeTo.isValid() ? values.timeTo : null}
                  onChange={value => {
                    setFieldValue('timeTo', value);
                  }}
                />
                <SimpleCheckbox
                  divClassName={'pt-7'}
                  name={'endless'}
                  label={'Бессрочно'}
                  onChange={handleChange}
                  checked={values.endless}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              {el.childPromotions?.length ? (
                <div className="flex gap-x-1">
                  <WarningExclamationSmall />
                  <p className={'Caption-small text-smena_text-secondary'}>
                    Параметры
                    <span
                      className={'text-primary cursor-pointer'}
                      onClick={() => {
                        setShowFacilitiesPopup(true);
                      }}
                    >
                      {` ${el.childPromotions.length} ${morph(el.childPromotions.length, ['объекта', 'объектов', 'объектов'])} `}
                    </span>
                    отличаются от параметров в этой группе
                  </p>
                </div>
              ) : null}
              {!isDeleted ? (
                <div className={clsx('flex items-center justify-between')}>
                  <button className={'btn-stroke_reject'} type={'button'} onClick={onRemovePromotion}>
                    Удалить
                  </button>
                  <button className={'btn-secondary'} type={'button'} onClick={onPublishBonus}>
                    {isExist ? 'Изменить бонус. программу' : 'Опубликовать бонус. программу'}
                  </button>
                </div>
              ) : null}
              <FacilitiesPopup show={showFacilitiesPopup} setShow={setShowFacilitiesPopup} promotion={el} />
              <EditBonusProgramPopup show={showEditPopup} setShow={setShowEditPopup} promotion={el} />
              <RemoveBonusProgramPopup show={showRemovePopup} setShow={setShowRemovePopup} promotion={el} />
            </Form>
          );
        }}
      </Formik>
    </Spoiler>
  );
};
export default Promotion;
