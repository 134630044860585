import React from 'react';
import TextArea from 'ui/textArea';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { FormikHandlers } from 'formik';
import MultiSelect from 'ui/multiSelect';
import { CasingDocumentTypes } from '../util/const';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (name: string, value: any) => void;
}

const VacancyDescription = ({ values, handleChange, setFieldValue }: Props) => {
  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Описание вакансии</span>
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Описание'}
        name={'description'}
        maxLength={1000}
        onChange={handleChange}
        value={values.description}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите описание'}
      />
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Обязанности'}
        name={'responsibilities'}
        onChange={handleChange}
        maxLength={1000}
        value={values.responsibilities}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите обязанности'}
      />
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Требования'}
        name={'requirements'}
        onChange={handleChange}
        maxLength={1000}
        value={values.requirements}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите требования'}
      />
      <div className={'flex gap-4'}>
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Отдел или секция"
          placeholder={'Введите отдел или секцию'}
          onChange={handleChange}
          value={values.section}
          type="text"
          name="section"
        />

        <MultiSelect
          divClassName="flex-[1_0]"
          value={values.requiredDocuments}
          selectClassName={'w-full'}
          label={'Необходимые документы'}
          name={'requiredDocuments'}
          placeholder={'Не выбран'}
          options={CasingDocumentTypes}
          onChange={value => {
            setFieldValue('requiredDocuments', value);
          }}
        />
        <div className={'flex-[1_0]'}></div>
      </div>
      <div className={'flex gap-x-4'}>
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Пробный период"
          placeholder="4"
          onChange={handleChange}
          regText
          value={values.probationPeriod}
          type="number"
          name="probationPeriod"
        />
        <div className="flex-[1_0]"></div>
        <div className="flex-[1_0]"></div>
      </div>
      <SimpleCheckbox
        divClassName="flex items-center"
        label="Обучение"
        onChange={handleChange}
        checked={values.hasEducation}
        name="hasEducation"
      />
      <SimpleCheckbox
        divClassName="flex items-center"
        label="Спец. одежда"
        required={false}
        onChange={handleChange}
        checked={values.workwear}
        name="workwear"
      />
    </>
  );
};

export default VacancyDescription;
