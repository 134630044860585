import { useState } from 'react';
import { Formik } from 'formik';

import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast } from 'utils/helpers/notify';
import { convertPhoneToString } from 'utils/helpers/convertPhone';
import { useGetAuthOptionMutation } from '../api/mutations/generated/GetAuthOption';
import SimpleInput from 'ui/input';
import { IMask } from 'react-imask';

interface setLoginPage {
  setLoginPage: (login: string) => void;
}

const Login = ({ setLoginPage }: setLoginPage) => {
  const [agree, setAgree] = useState(false);
  const { setPhoneNumber } = useStore();
  const [getAuthOption, { loading }] = useGetAuthOptionMutation();
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const masked = IMask.createMask({
    mask: '+7 (000) 000-00-00',
  });

  return (
    <div className="bg-smena_white shadow-smena rounded-lg px-5 py-7">
      <Formik
        initialValues={{ phone: '' }}
        validate={values => {
          const errors: any = {};
          if (!values.phone) {
            errors.phone = 'Обязательное поле';
          }
          return errors;
        }}
        onSubmit={values => {
          getAuthOption({
            variables: {
              phone: convertPhoneToString(values.phone),
            },
          })
            .then(response => {
              if (response.data?.getAuthOption.applicationType === 'dashboard') {
                if (response.data.getAuthOption.loginMethod) {
                  setPhoneNumber(convertPhoneToString(values.phone));
                  setLoginPage(response.data.getAuthOption.loginMethod);
                }
              } else {
                errorToast('Пользователь не может пользоваться админкой');
              }
            })
            .catch(e => {
              errorToast(errorHandler(e));
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <SimpleInput
                label={'Телефон'}
                name={'phone'}
                type={'text'}
                onKeyUp={e => {
                  const input = e.target as HTMLInputElement;
                  masked.resolve(input.value);
                  input.value = masked.value;
                  setFieldValue('phone', masked.value);
                }}
                onPaste={event => {
                  event.preventDefault();
                  const paste = event.clipboardData.getData('text');
                  masked.resolve(paste);
                  setFieldValue('phone', masked.value);
                }}
                maxLength={18}
                onChange={handleChange}
                value={values.phone}
                error={touched.phone && errors.phone ? errors.phone : ''}
                placeholder="Введите ваш телефон"
              />
              <div className="flex items-center gap-x-3">
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  className="checkbox-primary"
                  onChange={checkboxHandler}
                  checked={agree}
                />
                <label className="flex gap-x-1 font-semibold text-sm text-smena_gray-60">
                  <span>Согласен с условием</span>
                  <a href="/offer" className="text-primary" target="_blank">
                    оферты
                  </a>
                </label>
              </div>
              <button type="submit" disabled={!agree || loading} className="btn-primary_big">
                {loading ? 'Загрузка' : 'Далее'}
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
