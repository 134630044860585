import Plus from 'assets/icons/plus';
import React, { useEffect } from 'react';
import {
  useGetListPromotionsForFacilityGroupLazyQuery
} from 'pages/facilityGroup/api/queries/generated/GetListPromotionsForFacilityGroup';
import { useFacilityGroupExtended } from '../../../../utils/api/useFacilityGroup';
import { nanoid } from 'nanoid';
import Promotion from './promotion';
import { usePromotionStore } from '../../useBonusProgramStore';
import dayjs from 'dayjs';

const PromotionBlock = () => {
  const { id } = useFacilityGroupExtended();
  const promotions = usePromotionStore(state => state.promotions);
  const setPromotions = usePromotionStore(state => state.setPromotions);
  const addPromotions = usePromotionStore(state => state.addPromotions);
  const [loadPromotions] = useGetListPromotionsForFacilityGroupLazyQuery();
  const addPromotionHandler = () => {
    const newId = nanoid();
    addPromotions([
      {
        id: newId,
        badShiftCount: undefined,
        name: '',
        repeatCondition: undefined,
        requirementShiftCount: undefined,
        rewardSum: undefined,
        rewardType: undefined,
        status: 'Blank',
        dateTo: null,
        timeTo: null,
        dateFrom: null,
        timeFrom: null,
        endless: false,
      },
    ]);
  };


  useEffect(() => {
    if (!id) return;
    loadPromotions({ variables: { input: { facilityGroupId: id } } }).then(res => {
      const promotions = res.data?.getListPromotionsForFacilityGroup;
      if (promotions?.length) {
        return setPromotions(
          promotions.map(el => ({
            ...el,
            dateFrom: el.dateTimeStart ? dayjs(el.dateTimeStart) : null,
            timeFrom: el.dateTimeStart ? dayjs(el.dateTimeStart) : null,
            dateTo: el.dateTimeEnd ? dayjs(el.dateTimeEnd) : null,
            timeTo: el.dateTimeEnd ? dayjs(el.dateTimeEnd) : null,
            endless: false,
          })),
        );
      }
    });
  }, [id, loadPromotions, setPromotions]);

  useEffect(() => {
    return () => {
      setPromotions([]);
    };
  }, [setPromotions]);
  return (
    <div className="groupPageBlock blockTemplate">
      <div className="grid grid-cols-6 gap-y-6">
        <span className="blockTitleTemplate">Управление бонусными программами</span>
        <p className={'Body1 text-smena_text-secondary col-span-full'}>
          Настройка бонусных программ здесь повлияет на бонусные программы всех объектов этой группы. Исключение:
          бонусы, которые были созданы или изменены непосредственно на объекте.
        </p>
        {promotions.map((el) => (
          <Promotion key={el.id} el={el} />
        ))}
        <button
          className={
            'Button1 text-primary col-span-full border-dashed border-primary border rounded-lg flex gap-x-2 items-center justify-center py-2'
          }
          type={'button'}
          onClick={addPromotionHandler}
        >
          <Plus />
          Добавить бонусную программу
        </button>
      </div>
    </div>
  );
};

export default PromotionBlock;
