import type { FormikHandlers, FormikState } from 'formik';
import SimpleInput from 'ui/input';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
};

const Requirements = (props: Props) => {
  const { values, handleChange, touched, errors } = props;

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Требования</span>
      <div className="flex gap-6">
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Кол-во рефералов"
          placeholder="Кол-во рефералов"
          maxLength={30}
          onChange={handleChange}
          value={values.numberOfReferrals}
          name="numberOfReferrals"
          type="number"
          allowIntegerOnly
          error={touched.numberOfReferrals && errors.numberOfReferrals ? errors.numberOfReferrals : ''}
        />

        <SimpleInput
          divClassName="flex-[1_0]"
          label="Кол-во хорошо отработанных смен"
          placeholder="Кол-во хорошо отработанных смен"
          maxLength={30}
          onChange={handleChange}
          value={values.numberOfGoodShifts}
          name="numberOfGoodShifts"
          type="number"
          allowIntegerOnly
          error={touched.numberOfGoodShifts && errors.numberOfGoodShifts ? errors.numberOfGoodShifts : ''}
        />
      </div>
    </>
  );
};

export default Requirements;
