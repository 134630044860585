import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleSelect from 'ui/select';
import Filter from 'components/table/filters/filter';
import { getLeadStatusRu } from 'utils/helpers/get/getLeadStatus';
import { ISO_DATE, leadStatusOptions, RU_DATE } from 'utils/helpers/constVariables';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import dayjs from 'dayjs';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import TimesheetLoader from '../../timesheets/ui/Loader';
import { useCreateLeadsReportMutation } from '../api/mutations/generated/CreateLeadsReport';
import { LeadFieldsForSorting, LeadStatus, Role, SortingWay } from 'generated/graphql';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { rolesForStoryAndPush } from 'utils/helpers/lists/roleArray';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { LeadsFragment } from '../api/fragmetns/generated/Leads.fragment';
import { Table } from '@tanstack/react-table';
import ColumnSettings from 'components/table/columnSettings';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { useMeRole } from 'store/hooks/useMeRole';

interface Props {
  table: Table<LeadsFragment>;
}

const LeadsFilters = ({ table }: Props) => {
  const isMeAdmin = useMeRole([Role.Admin]);
  const { params, setParams, handleParams, resetFilter } = useCustomSearchParams();
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');
  const statusParams = params.get('status');
  const roleParams = params.get('role');
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const achievementDateFromParam = params.get('achievementDateFrom');
  const achievementDateToParam = params.get('achievementDateTo');
  const dateFrom = dateFromParam ? dayjs(dateFromParam) : null;
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : null;
  const achievementDateFrom = achievementDateFromParam ? dayjs(achievementDateFromParam) : null;
  const achievementDateTo = achievementDateToParam ? dayjs(achievementDateToParam).endOf('day') : null;
  const searchParams = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParams = params.get('page');
  const page = pageParams ? +pageParams : 1;

  const [status, setStatus] = useState(statusParams);
  const [role, setRole] = useState(roleParams);
  const [facilityGroupId, setFacilityGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityIdParam);
  const [value, setValue] = useState<DayOrNullArray>([dateFrom, dateTo]);
  const [achievementDate, setAchievementDate] = useState<DayOrNullArray>([achievementDateFrom, achievementDateTo]);
  const [showFilter, setShowFilter] = useState(false);
  const [createLeadsReport, { loading }] = useCreateLeadsReportMutation();
  const [resLink, setResLink] = useState('');
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const onDownloadReport = () => {
    createLeadsReport({
      variables: {
        input: {
          page,
          searchQuery: searchParams,
          role: role as Role,
          status: statusParams as LeadStatus,
          sortBy: sortBy as LeadFieldsForSorting,
          sortWay: sortWay as SortingWay,
          inviteDateFrom: dateFrom,
          inviteDateTo: dateTo,
          achieveDateFrom: achievementDateFrom,
          achieveDateTo: achievementDateTo,
          facilityId: facilityIdParam,
          facilityGroupId: facilityGroupIdParam,
        },
      },
    })
      .then(response => {
        if (response.data?.createLeadsReport) {
          const filePath = response.data.createLeadsReport.filePath;
          const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
          setResLink(url);
        }
      })
      .catch(e => {
        errorToast(errorHandler(e));
      });
  };

  const btnRef = useRef<HTMLButtonElement>(null);

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };
  paramCounter('status');
  paramCounter('role');
  paramCounter('facilityGroupId');
  paramCounter('facilityId');
  if (params.has('dateFrom') || params.has('dateTo')) {
    paramsCount++;
  }
  if (params.has('achievementDateFrom') || params.has('achievementDateTo')) {
    paramsCount++;
  }

  const { facilities } = useSelectFacilities();
  const { facility } = useFacilityById(facilityIdParam);
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilityGroupId, facilities],
  );
  return (
    <div className="filter-bar">
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <div className="flex relative gap-x-2">
            <Search />
            <button
              type="button"
              ref={btnRef}
              className="btn-stroke flex gap-x-1 items-center"
              onClick={() => {
                setShowFilter(value => !value);
              }}
            >
              Фильтр
              {Boolean(paramsCount) && (
                <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                  {paramsCount}
                </span>
              )}
            </button>
            <ColumnSettings<LeadsFragment> table={table} />
            <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
              <RangePickerInput
                label="Даты добавления лида"
                divClassName="w-full"
                value={value && [value[0], value[1]]}
                onChange={newValue => {
                  setValue(newValue);
                }}
              />
              <SimpleSelect
                label="Роль реферера"
                onChange={value => {
                  setRole(value);
                }}
                value={role}
                placeholder="Не выбрано"
                allOption="Все"
                options={rolesForStoryAndPush.map(el => ({ id: el.value, name: el.name }))}
              />
              <SimpleSelect
                label="Группа"
                popupClassName={'max-w-[300px]'}
                onChange={value => {
                  setFacilityGroupId(value);
                  setFacilityId(null);
                }}
                value={facilityGroupId}
                placeholder="Не выбрано"
                allOption="Все"
                options={facilitiesGroups}
              />
              <SimpleSelect
                label="Объект"
                onChange={value => {
                  setFacilityId(value);
                }}
                value={facilityId}
                placeholder="Не выбрано"
                allOption="Все"
                options={filteredFacilities}
              />
              <SimpleSelect
                label="Статус лида"
                onChange={value => {
                  setStatus(value);
                }}
                value={status}
                placeholder="Не выбрано"
                allOption="Все"
                options={leadStatusOptions}
              />
              <RangePickerInput
                label="Даты достижения цели по сменам"
                value={achievementDate && [achievementDate[0], achievementDate[1]]}
                onChange={newValue => {
                  setAchievementDate(newValue);
                }}
              />
              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    handleParams('status', status);
                    handleParams('role', role);
                    handleParams('facilityGroupId', facilityGroupId);
                    handleParams('facilityId', facilityId);
                    if (value?.filter(Boolean).length) {
                      params.set('dateFrom', String(dayjs(value[0]).format(ISO_DATE)));
                      params.set('dateTo', String(dayjs(value[1]).format(ISO_DATE)));
                    } else {
                      if (params.has('dateFrom') && params.has('dateTo')) {
                        params.delete('dateFrom');
                        params.delete('dateTo');
                      }
                    }
                    if (achievementDate?.filter(Boolean).length) {
                      params.set('achievementDateFrom', String(dayjs(achievementDate[0]).format(ISO_DATE)));
                      params.set('achievementDateTo', String(dayjs(achievementDate[1]).format(ISO_DATE)));
                    } else {
                      if (params.has('achievementDateFrom') && params.has('achievementDateTo')) {
                        params.delete('achievementDateFrom');
                        params.delete('achievementDateTo');
                      }
                    }
                    setParams(params);
                    setShowFilter(false);
                  }}
                >
                  Применить
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setValue(null);
                    setAchievementDate(null);
                    setRole(null);
                    setStatus(null);
                    setFacilityGroupId(null);
                    setFacilityId(null);
                    setParams();
                    setShowFilter(false);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </TableFilter>
          </div>
          {Boolean(paramsCount) && (
            <div className="flex">
              <Filter
                show={!!(dateFrom || dateTo)}
                content={dateFrom?.format(RU_DATE) + ' - ' + dateTo?.format(RU_DATE)}
                clickHandler={() => {
                  setValue(null);
                  params.delete('dateFrom');
                  params.delete('dateTo');
                  params.delete('page');
                  setParams(params);
                }}
              />
              <Filter
                show={!!(achievementDateFrom || achievementDateTo)}
                content={achievementDateFrom?.format(RU_DATE) + ' - ' + achievementDateTo?.format(RU_DATE)}
                clickHandler={() => {
                  setAchievementDate(null);
                  params.delete('achievementDateFrom');
                  params.delete('achievementDateTo');
                  params.delete('page');
                  setParams(params);
                }}
              />
              <Filter
                show={!!statusParams}
                content={getLeadStatusRu(status)}
                clickHandler={() => {
                  setStatus(null);
                  resetFilter('status');
                }}
              />
              <Filter
                show={!!roleParams}
                content={getRoleRu(roleParams)}
                clickHandler={() => {
                  setRole(null);
                  resetFilter('role');
                }}
              />
              <Filter
                show={!!facilityGroupIdParam}
                content={facilityGroupName}
                clickHandler={() => {
                  setFacilityGroupId(null);
                  resetFilter('facilityGroupId');
                }}
              />
              <Filter
                show={!!facilityIdParam}
                content={facility?.name}
                clickHandler={() => {
                  setFacilityId(null);
                  resetFilter('facilityId');
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-start gap-2">
          {isMeAdmin && (
            <Link to="/offers/leads/setting">
              <button className="btn-stroke" onClick={onDownloadReport}>
                Настройка реферальной программы
              </button>
            </Link>
          )}

          <button className="btn-primary" onClick={onDownloadReport} disabled={loading}>
            Сформировать отчет
          </button>
          <TimesheetLoader loading={loading} resLink={resLink} />
        </div>
      </div>
    </div>
  );
};

export default LeadsFilters;
