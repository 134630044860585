import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { UsersPaginatedFragmentDoc } from '../../fragments/generated/UsersPaginated.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersPaginatedQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UserListInput>;
}>;


export type UsersPaginatedQuery = { __typename?: 'Query', usersPaginated: { __typename?: 'PaginatedUsers', count: number, pages: number, users: Array<{ __typename?: 'User', id: string, lastname: string, firstname: string, patronymic?: string | null, role: Types.Role, phone: string, currentVersion?: string | null, isActive: boolean, registrationState?: { __typename?: 'UserRegistrationState', personalInfo: Types.UserPersonalInfoStatus, taxPartnerInfo: Types.TaxPartnerInfo, paymentInfo: Types.PaymentInfoEnum } | null, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, facilitiesManager?: Array<{ __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null }> | null, workposts?: Array<{ __typename?: 'WorkPost', facilityId: string, positionId: string, userId: string, status: Types.WorkPostStatus, facility?: { __typename?: 'Facility', id: string, name: string, facilityGroupId?: string | null } | null }> | null, Facility_SUPERVISOR?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, contracts?: Array<{ __typename?: 'UserContract', id: string, type: Types.UserContractType, status: Types.UserContractStatus, url?: string | null, createdAt: any }> | null }> } };


export const UsersPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UsersPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UserListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UsersPaginated"}}]}}]}}]}},...UsersPaginatedFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
      }
export function useUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
        }
export function useUsersPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(UsersPaginatedDocument, options);
        }
export type UsersPaginatedQueryHookResult = ReturnType<typeof useUsersPaginatedQuery>;
export type UsersPaginatedLazyQueryHookResult = ReturnType<typeof useUsersPaginatedLazyQuery>;
export type UsersPaginatedSuspenseQueryHookResult = ReturnType<typeof useUsersPaginatedSuspenseQuery>;
export type UsersPaginatedQueryResult = Apollo.QueryResult<UsersPaginatedQuery, UsersPaginatedQueryVariables>;