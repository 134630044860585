import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { FacilityFragmentDoc } from '../../fragments/generated/Facility.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type FacilityQuery = { __typename?: 'Query', facility: { __typename?: 'Facility', id: string, externalId?: string | null, name: string, isMedInvoiceAllowed: boolean, numberDaysWaitMedical: number, checkInMeasurement: number, checkOutMeasurement: number, naimixObjectId?: string | null, naimixObjectName?: string | null, naimixScenarioNumber?: string | null, facilityGroupId?: string | null, metro?: string | null, metroStationsIds?: Array<string> | null, cityId?: string | null, addressString?: string | null, is_active?: boolean | null, is_bonus_active: boolean, is_finance_active: boolean, showSalaryBeforeModeration: boolean, latitude: string, longitude: string, radius: number, everythingIsPossibleBonus: boolean, possibleBonusPaymentSum: number, possibleBonusShiftCount: number, possibleBonusViewSum: number, city?: { __typename?: 'City', id: string, name: string, latitude: string, longitude: string, timeZone: string, externalId?: string | null } | null, legalEntity?: { __typename?: 'LegalEntity', id: string, name: string } | null, foremen?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string }> | null, supervisors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null, facilityGroup?: { __typename?: 'FacilityGroup', id: string, name: string, clientDirectors?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } | null, penalty?: Array<{ __typename?: 'Penalty', id: string, createdAt: any, applyTimeInMinutes: number, comment?: string | null, cost: number, facilityId?: string | null, type: Types.PenaltyType, isDeleted: boolean }> | null, clientManagers?: Array<{ __typename?: 'User', id: string, role: Types.Role, firstname: string, lastname: string, patronymic?: string | null }> | null } };


export const FacilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Facility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Facility"}}]}}]}},...FacilityFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useFacilityQuery__
 *
 * To run a query within a React component, call `useFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilityQuery(baseOptions: Apollo.QueryHookOptions<FacilityQuery, FacilityQueryVariables> & ({ variables: FacilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilityQuery, FacilityQueryVariables>(FacilityDocument, options);
      }
export function useFacilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilityQuery, FacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilityQuery, FacilityQueryVariables>(FacilityDocument, options);
        }
export function useFacilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FacilityQuery, FacilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FacilityQuery, FacilityQueryVariables>(FacilityDocument, options);
        }
export type FacilityQueryHookResult = ReturnType<typeof useFacilityQuery>;
export type FacilityLazyQueryHookResult = ReturnType<typeof useFacilityLazyQuery>;
export type FacilitySuspenseQueryHookResult = ReturnType<typeof useFacilitySuspenseQuery>;
export type FacilityQueryResult = Apollo.QueryResult<FacilityQuery, FacilityQueryVariables>;