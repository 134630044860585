import { ReferralProgramStatusEnum } from 'generated/graphql';

export const getReferralProgramStatus = (status: ReferralProgramStatusEnum): string => {
  const statuses: Record<ReferralProgramStatusEnum, string> = {
    [ReferralProgramStatusEnum.Active]: 'Активно',
    [ReferralProgramStatusEnum.Archive]: 'Архив',
    [ReferralProgramStatusEnum.Draft]: 'Черновик',
    [ReferralProgramStatusEnum.Planned]: 'Запланировано',
  };

  return statuses[status] || 'Неизвестный статус';
};
