import Leads from 'pages/leads';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StocksPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/offers/leads');
  }, [navigate]);
  return <Leads />;
};

export default StocksPage;
