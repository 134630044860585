import React from 'react';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import useVacancyPage from './util/hooks';
import { useVacancyBreadcrumbs, useVacancyTitle } from './util/hooks/usePageProps';
import MainInfo from './components/MainInfo';
import Payments from './components/Payments';
import VacancyDescription from './components/VacancyDescription';
import DescriptionForRecruiter from './components/DescriptionForRecruiter';
import Erp from './components/erp';

const UpdateVacancy = () => {
  const { onSubmit, validate, loading, initialValues, error } = useVacancyPage();

  const title = useVacancyTitle();
  const breadcrumbs = useVacancyBreadcrumbs();

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs} error={error}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, errors, handleChange, setFieldValue }) => {
          return (
            <Form className="grid grid-cols-12 gap-y-5">
              <div className="vacancyPageBlock blockTemplate">
                <MainInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                />
                <hr />
                <Payments
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                />
                <hr />
                <VacancyDescription values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
                <hr />
                <DescriptionForRecruiter
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
                <Erp values={values} handleChange={handleChange} />
              </div>

              <div className="vacancyPageBlock justify-self-end">
                <button type="submit" disabled={loading} className="btn-primary_big">
                  {loading ? 'Загрузка' : 'Сохранить'}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateVacancy;
