import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { VacancyFragmentDoc } from '../../fragments/generated/Vacancy.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVacancyMutationVariables = Types.Exact<{
  input: Types.VacancyInput;
}>;


export type CreateVacancyMutation = { __typename?: 'Mutation', createVacancy: { __typename?: 'Vacancy', id: string, facilityId: string, lunchDuration?: number | null, erpSourceId?: string | null, erpGroupId?: string | null, timeStart: string, timeEnd: string, hourRate: number, hasEducation?: boolean | null, probationPeriod?: number | null, is_active: boolean, period: Types.ShiftTime, positionId: string, documentsNotes?: string | null, requiredDocuments: Array<Types.CasingDocumentType>, scheduleTypesIds?: Array<string> | null, section?: string | null, payout?: Types.PayoutType | null, unit?: Types.UnitType | null, isPublishCostOfShift: boolean, isRangeCost: boolean, averageCost: number, shiftCostFrom: number, shiftCostUpTo: number, payoutFrequency?: Types.PayoutFrequency | null, description?: string | null, maxAge?: number | null, minAge?: number | null, notes?: string | null, requirements?: string | null, responsibilities?: string | null, sex?: Types.Sex | null, citizenshipsIds?: Array<string> | null, transport?: string | null, workwear: boolean, facility: { __typename?: 'Facility', name: string, cityId?: string | null, facilityGroupId?: string | null }, position?: { __typename?: 'Position', name: string } | null } };


export const CreateVacancyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateVacancy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VacancyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createVacancy"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Vacancy"}}]}}]}},...VacancyFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateVacancyMutationFn = Apollo.MutationFunction<CreateVacancyMutation, CreateVacancyMutationVariables>;

/**
 * __useCreateVacancyMutation__
 *
 * To run a mutation, you first call `useCreateVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacancyMutation, { data, loading, error }] = useCreateVacancyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacancyMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacancyMutation, CreateVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVacancyMutation, CreateVacancyMutationVariables>(CreateVacancyDocument, options);
      }
export type CreateVacancyMutationHookResult = ReturnType<typeof useCreateVacancyMutation>;
export type CreateVacancyMutationResult = Apollo.MutationResult<CreateVacancyMutation>;
export type CreateVacancyMutationOptions = Apollo.BaseMutationOptions<CreateVacancyMutation, CreateVacancyMutationVariables>;