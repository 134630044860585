import type { FormikHandlers, FormikState } from 'formik';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (name: string, value: any) => void;
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
};

const Dates = (props: Props) => {
  const { values, setFieldValue, touched, errors } = props;

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Сроки проведения</span>

      <div className="flex gap-6">
        <DatePickerInput
          divClassName="w-full"
          label="Дата начала"
          popupClassName="fixed"
          value={values.startDate}
          onChange={startDate => {
            setFieldValue('startDate', startDate);
          }}
          error={touched.startDate && errors.startDate ? errors.startDate : ''}
        />

        <TimePickerInput
          label="Время начала"
          className="w-full"
          popupClassName="fixed"
          value={values.startTime}
          onChange={startTime => {
            setFieldValue('startTime', startTime);
          }}
          error={touched.startTime && errors.startTime ? errors.startTime : ''}
        />
      </div>
    </>
  );
};

export default Dates;
