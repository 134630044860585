import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { VacancyFragmentDoc } from '../../../../vacancy/api/fragments/generated/Vacancy.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VacanciesPaginatedQueryVariables = Types.Exact<{
  input: Types.VacancyListInput;
}>;


export type VacanciesPaginatedQuery = { __typename?: 'Query', vacanciesPaginated: { __typename?: 'PaginatedVacancies', count: number, pages: number, vacancies: Array<{ __typename?: 'Vacancy', id: string, facilityId: string, lunchDuration?: number | null, erpSourceId?: string | null, erpGroupId?: string | null, timeStart: string, timeEnd: string, hourRate: number, hasEducation?: boolean | null, probationPeriod?: number | null, is_active: boolean, period: Types.ShiftTime, positionId: string, documentsNotes?: string | null, requiredDocuments: Array<Types.CasingDocumentType>, scheduleTypesIds?: Array<string> | null, section?: string | null, payout?: Types.PayoutType | null, unit?: Types.UnitType | null, isPublishCostOfShift: boolean, isRangeCost: boolean, averageCost: number, shiftCostFrom: number, shiftCostUpTo: number, payoutFrequency?: Types.PayoutFrequency | null, description?: string | null, maxAge?: number | null, minAge?: number | null, notes?: string | null, requirements?: string | null, responsibilities?: string | null, sex?: Types.Sex | null, citizenshipsIds?: Array<string> | null, transport?: string | null, workwear: boolean, facility: { __typename?: 'Facility', name: string, cityId?: string | null, facilityGroupId?: string | null }, position?: { __typename?: 'Position', name: string } | null }> } };


export const VacanciesPaginatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VacanciesPaginated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VacancyListInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vacanciesPaginated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"vacancies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Vacancy"}}]}}]}}]}},...VacancyFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useVacanciesPaginatedQuery__
 *
 * To run a query within a React component, call `useVacanciesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanciesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanciesPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVacanciesPaginatedQuery(baseOptions: Apollo.QueryHookOptions<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables> & ({ variables: VacanciesPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>(VacanciesPaginatedDocument, options);
      }
export function useVacanciesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>(VacanciesPaginatedDocument, options);
        }
export function useVacanciesPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>(VacanciesPaginatedDocument, options);
        }
export type VacanciesPaginatedQueryHookResult = ReturnType<typeof useVacanciesPaginatedQuery>;
export type VacanciesPaginatedLazyQueryHookResult = ReturnType<typeof useVacanciesPaginatedLazyQuery>;
export type VacanciesPaginatedSuspenseQueryHookResult = ReturnType<typeof useVacanciesPaginatedSuspenseQuery>;
export type VacanciesPaginatedQueryResult = Apollo.QueryResult<VacanciesPaginatedQuery, VacanciesPaginatedQueryVariables>;