import React from 'react';
import { MapVacancyFragment } from '../../api/fragments/generated/MapVacancy.fragment';
import { getScheduleTypeName } from 'utils/api/useGetScheduleTypes/getScheduleTypeName';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { useVacanciesMap } from '../../store';
import { useCost } from '../../utils/hook';
import { durationText, lunchDurationText } from '../../utils/helper';
import { getPeriodBlock } from '../../utils/getPeriod';
import CoffeeIcon from 'assets/icons/coffeeIcon';
import clsx from 'clsx';

interface Props {
  vacancy: MapVacancyFragment;
}

const Vacancy = ({ vacancy }: Props) => {
  const { scheduleTypes } = useGetScheduleTypes();
  const setActiveVacancy = useVacanciesMap(state => state.setActiveVacancy);
  const activeVacancy = useVacanciesMap(state => state.activeVacancy);
  const cost = useCost(vacancy);
  const duration = durationText(vacancy);
  const lunchDuration = lunchDurationText(vacancy.lunchDuration);
  return (
    <div
      className={clsx(
        'flex flex-col gap-y-2 px-6 py-3 border-b border-smena_gray-30 cursor-pointer',
        activeVacancy?.id === vacancy.id ? 'bg-smena_bb-background' : 'hover:bg-smena_bb-background_light',
      )}
      onClick={() => {
        setActiveVacancy(vacancy);
      }}
    >
      <div>
        <div className={'Subtitle1'}>{vacancy.position?.name}</div>
        <div className={'Subtitle2 text-primary'}>{cost}</div>
      </div>
      <div>
        <div className={'Subtitle2'}>Продолжительность</div>
        <span className={'Body2'}>{`${vacancy.timeStart} - ${vacancy.timeEnd} ${duration}`}</span>
        <div className={'flex gap-2 Body2 flex-wrap'}>
          <div className={'flex gap-x-2 Caption-small pl-1 pr-2 py-0.5 rounded-lg border border-smena_gray-30'}>
            {getPeriodBlock(vacancy.period)}
          </div>
          <div className={'flex gap-x-2 Caption-small pl-1 pr-2 py-0.5 rounded-lg border border-smena_gray-30'}>
            <CoffeeIcon />
            <span>{lunchDuration}</span>
          </div>
        </div>
      </div>
      <div>
        <div className={'Subtitle2'}>Типы графиков</div>
        <div className={'flex gap-1 flex-wrap'}>
          {vacancy.scheduleTypesIds?.map(el => (
            <span key={el} className={'Body2 px-2 py-0.5 border border-smena_gray-30 rounded-lg'}>
              {getScheduleTypeName(scheduleTypes, el)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Vacancy;
