import React from 'react';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import FacilityForemenSupervisors from './components/facilityResponsibility/ForemanSupervisor';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import useFacilityPageForm from './lib/hooks/useFacilityPageForm';
import { useMeRole } from 'store/hooks/useMeRole';
import ManagersAndDirectorsBlock from './components/facilityResponsibility/ManagersAndDirectorsBlock';
import FacilityMedicalInvoice from './components/facilityMedicalInvoice';
import MainInfoFacility from './components/mainInfo';
import FacilityAccess from './components/facilityAccess';
import FacilityMapAndCoordinates from './components/mapAndCoordinates';
import FacilityPenalty from './components/facilityPenalty';
import FacilityAllIsPossible from './components/facilityAllIsPossible';
import { useFacility } from 'utils/api/useFacility';
import { useFacilityPageTitle } from './lib/hooks/useFacilityPageProps';
import { Role } from 'generated/graphql';
import PromotionBlock from './components/promotionBlock';

const UpdateFacility = () => {
  const { error, loading, id } = useFacility();
  const isMeAdmin = useMeRole([Role.Admin]);
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);

  const title = useFacilityPageTitle();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();

  const { initialValues, validate, onSubmit, loading: facilityMutations } = useFacilityPageForm();

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error} loading={loading}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <div className="grid grid-cols-12 md:gap-x-6 gap-y-6">
              <Form className={'col-span-full grid grid-cols-12 md:gap-x-6 gap-y-6'}>
                <div className="facilityPageBlock blockTemplate">
                  <MainInfoFacility
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    values={values}
                  />

                  <FacilityMapAndCoordinates
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  {isMeAdminOrSupervisor && (
                    <div className="flex justify-end">
                      <button type="submit" disabled={facilityMutations} className="btn-primary_big">
                        {facilityMutations ? 'Загрузка' : id ? 'Сохранить' : 'Создать'}
                      </button>
                    </div>
                  )}
                </div>
                {isMeAdmin && <FacilityAccess handleChange={handleChange} values={values} />}
              </Form>

              {!!id && (
                <>
                  <ManagersAndDirectorsBlock />
                  <FacilityForemenSupervisors />
                  <PromotionBlock />
                  {isMeAdminOrSupervisor && <FacilityPenalty />}
                  <FacilityAllIsPossible />
                  <FacilityMedicalInvoice />
                </>
              )}
            </div>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateFacility;
