import { useFacilitiesQuery } from 'pages/main/api/queries/generated/Facilities';
import { useMemo } from 'react';
import { sortObj } from '../../helpers/sortObject';

export const useSelectFacilities = () => {
  const { data, loading, error } = useFacilitiesQuery();

  const facilities = useMemo(() => sortObj(data?.facilities), [data?.facilities]);

  return { facilities, loading, error };
};
