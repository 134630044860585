import type { FormikHandlers, FormikState } from 'formik';
import SimpleInput from 'ui/input';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
};

const Rewards = (props: Props) => {
  const { values, handleChange, touched, errors } = props;

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Вознаграждения</span>

      <div className="flex gap-6">
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Вознаграждение реферера"
          placeholder="Вознаграждение реферера"
          maxLength={30}
          onChange={handleChange}
          value={values.rewardForTheReferer}
          name="rewardForTheReferer"
          type="number"
          allowIntegerOnly
          error={touched.rewardForTheReferer && errors.rewardForTheReferer ? errors.rewardForTheReferer : ''}
        />

        <SimpleInput
          divClassName="flex-[1_0]"
          label="Вознаграждение  реферала"
          placeholder="Вознаграждение  реферала"
          maxLength={30}
          onChange={handleChange}
          value={values.rewardForTheReferral}
          name="rewardForTheReferral"
          type="number"
          helperText="Можно оставить пустым"
          allowIntegerOnly
          error={touched.rewardForTheReferral && errors.rewardForTheReferral ? errors.rewardForTheReferral : ''}
        />
      </div>
    </>
  );
};

export default Rewards;
