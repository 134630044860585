import React, { useCallback, useMemo, useState } from 'react';
import ResponsibleUser from '../../../facility/components/responsibleUsers';
import AutocompleteBlock from 'ui/autocompleteBlock';
import { chain } from 'lodash';
import sortBy from 'lodash/sortBy';
import { useClientDirectors } from '../../../../utils/api/useClientDirectors';
import { useFacilityGroupExtended } from 'utils/api/useFacilityGroup';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from '../../../../utils/helpers/errorHandler';
import { addDirectorToFacilityGroupCache } from '../../api/updateCache/addDirectorToFacilityGroupCache';
import { useAddDirectorToFacilityGroupMutation } from 'pages/user/api/mutations/generated/AddDirectorToFacilityGroup';
import { undefStr } from 'interfaces/CustomTypes';

const ResponsibleBlock = () => {
  const [director, setDirector] = useState<undefStr>('');
  const { clientDirectors } = useClientDirectors();
  const { facilityGroup, id } = useFacilityGroupExtended();
  const [addDirectorToFacilityGroup, { loading: loadingDirector }] = useAddDirectorToFacilityGroupMutation({
    update: (cache, { data }) => {
      if (!id) return;
      addDirectorToFacilityGroupCache(cache, { data, groupId: id });
    },
  });
  const directorsOptions = chain(clientDirectors)
    .filter(el => !facilityGroup?.clientDirectors?.some(clientDirector => clientDirector.id === el.id))
    .sortBy(el => el.lastname)
    .value();
  const facilityGroupClientDirectors = useMemo(
    () => sortBy(facilityGroup?.clientDirectors, el => el.lastname),
    [facilityGroup?.clientDirectors],
  );

  const directorsArr = directorsOptions?.map(director => {
    const { id, lastname, firstname } = director;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });
  const addDirectorToFacilityGroupHandler = useCallback(
    (userId?: string) => {
      if (!id || !userId) return;
      addDirectorToFacilityGroup({
        variables: {
          groupId: id,
          userId,
        },
      })
        .then(() => successToast('Директор успешно добавлен'))
        .catch(err => errorToast(errorHandler(err)));
    },
    [addDirectorToFacilityGroup, id],
  );

  const managers = facilityGroup?.groupManagers;
  return (
    <div className="groupPageBlock blockTemplate">
      <span className="blockTitleTemplate">Представители</span>
      <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-5 border-b border-smena_gray-30">
        <div className="sm:col-span-4 col-span-8">
          <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline">
            <span className="Subtitle2">Директор</span>
            <div className={'grid gap-y-1'}>
              {facilityGroupClientDirectors?.length ? (
                facilityGroupClientDirectors.map(clientDirector => (
                  <ResponsibleUser key={clientDirector.id} user={clientDirector} />
                ))
              ) : (
                <span className="Body2 text-smena_text-secondary">Не назначен</span>
              )}
            </div>
          </div>
        </div>
        <div className="sm:col-span-4 col-span-8">
          <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline">
            <span className="Subtitle2">Менеджер</span>
            <div className={'grid gap-y-1'}>
              {managers?.length ? (
                managers.map(manager => <ResponsibleUser key={manager.id} user={manager} canRemove={false} />)
              ) : (
                <span className="Body2 text-smena_text-secondary">Не назначен</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-8 sm:mb-0 mb-2 gap-x-2">
        <AutocompleteBlock
          id="directorId"
          label="Директор"
          onChangeHandler={value => {
            setDirector(value?.id);
          }}
          value={director}
          options={directorsArr}
          buttonHandler={() => {
            if (!director) return;
            addDirectorToFacilityGroupHandler(director);
          }}
          loading={loadingDirector}
        />
      </div>
    </div>
  );
};

export default ResponsibleBlock;
