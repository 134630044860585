import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LeadsFragmentDoc } from '../../fragmetns/generated/Leads.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LeadsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.LeadListInput>;
}>;


export type LeadsQuery = { __typename?: 'Query', leads: { __typename?: 'PaginatedLeadsModel', count: number, pages: number, leads: Array<{ __typename?: 'LeadOutput', id: string, countShifts?: number | null, countShiftsBad?: number | null, countShiftsGood?: number | null, createdAt: any, isProcessed: boolean, status: Types.LeadStatus, updatedAt: any, userId: string, date?: any | null, referrerPaymentDatetime?: any | null, inviteFriendDate?: any | null, inviteFriendProgress: { __typename?: 'InviteFriendProgressOutput', currentShifts: number, sum: number, targetShifts: number }, user?: { __typename?: 'User', id: string, phone: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null, referrer?: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null, role: Types.Role, phone: string, Facility_FOREMAN?: Array<{ __typename?: 'Facility', id: string, name: string }> | null, workposts?: Array<{ __typename?: 'WorkPost', userId: string, status: Types.WorkPostStatus, positionId: string, facilityId: string, facility?: { __typename?: 'Facility', id: string, name: string } | null }> | null } | null } | null }> } };


export const LeadsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Leads"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadListInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"leads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Leads"}}]}}]}}]}},...LeadsFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useLeadsQuery__
 *
 * To run a query within a React component, call `useLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeadsQuery(baseOptions?: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
      }
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export function useLeadsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsSuspenseQueryHookResult = ReturnType<typeof useLeadsSuspenseQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;